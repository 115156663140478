import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import Chechbox from "../../components/Chechbox/Chechbox";
import Input from "../../components/Input/Input";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import RightIcon from "../../icons/arrow-right.svg";
import Logo from "../../images/GP-Logo.png";
import RegisterIcon from "../../icons/register.svg";
import { useRegisterMutation } from "../../services/UserService";
import { controlToErrorStates, controlToRegisterValues } from "./function";
import { Tooltip } from "@mui/material";

export default function Register() {
  //initial
  const navigate = useNavigate();
  //services
  const [register, { isLoading, isSuccess }] = useRegisterMutation({});
  //states
  const [userRegister, setUserRegister] = useState({
    email: { value: "", error: false },
    corporation: { value: "", error: false },
    name: { value: "", error: false },
    surname: { value: "", error: false },
    cellPhone: { value: "", error: false },
    password: { value: "", error: false },
    passwordRequret: { value: "", error: false },
    kvkk: { value: false, error: false },
  });

  async function registerUser() {
    await controlToRegisterValues(setUserRegister);

    if (userRegister.password.value !== userRegister.passwordRequret.value) {
      toast.error("Şifre ve Şifre tekrar aynı olmalıdır.");
      setUserRegister((userRegister) => ({
        ...userRegister,
        password: {
          value: "",
          error: true,
        },
        passwordRequret: {
          value: "",
          error: true,
        },
      }));
    } else {
      var errorState = await controlToErrorStates(userRegister);

      if (!errorState) {
        const res = await register({
          email: userRegister.email.value,
          corporation: userRegister.corporation.value,
          name: userRegister.name.value,
          surname: userRegister.surname.value,
          cellPhone: userRegister.cellPhone.value,
          password: userRegister.password.value,
        });

        if (res.error) {
          if (res.error.status === 0) {
            toast.error("Beklenmedik bir hata oldu.");
          } else {
            if (res.error.data?.message) {
              toast.error(res.error.data?.message);
            } else {
              toast.error("Beklenmedik bir hata oldu.");
            }
          }
        }
      }
    }
  }

  async function changeInput(changeJson) {
    await controlToRegisterValues(setUserRegister);
    setUserRegister(changeJson);
  }

  useEffect(() => {
    if (isSuccess === true) {
      toast.success(
        "Hesabınız Başarılı bir şekilde oluşturuldu. Lütfen mail adresinizden hesabınızı doğrulayın."
      );
      setTimeout(() => {
        navigate("/giris");
      }, 1000);
    }
  }, [isSuccess]);

  return (
    <>
      {" "}
      <LoadingSpinner state={isLoading}>
        <div className="h-screen w-screen bg-[#F6F7FA] flex flex-col justify-center items-center">
          <div className="-mt-8">
            <div className="flex ">
              <div className="flex-1">
                <img className="h-[77px] w-[198px] mb-[44px]" src={Logo}></img>
              </div>

              <div className="h-[60px] mb-10">
                <Button
                  iconLeft={RegisterIcon}
                  className={"py-[16px] px-[35px]"}
                  text={"Giriş Yap"}
                  onClick={() => navigate("/giris")}
                />
              </div>
            </div>

            <div className="w-[800px] h-[600px] bg-white rounded-[14px] p-[40px]">
              <div className="text-black text-5xl font-medium font-['Outfit']">
                Kayıt Ol
              </div>
              <div className="w-[720px] text-gray-500 text-base font-normal font-['Outfit'] mt-[31px]">
                Paketler, detaylı bilgi ve 30 günlük deneme hakkında bilgi almak
                içim kayıt olunuz.
              </div>
              <div className="flex mt-5">
                <div className="flex-1 pr-3 flex">
                  <div className="pr-4">
                    <Input
                      name={"Ad"}
                      placeholder={"Adınızı girin"}
                      type={"text"}
                      labelName={"Ad"}
                      regexType={"text"}
                      required={true}
                      error={userRegister.name.error}
                      value={userRegister.name.value}
                      maxLength={15}
                      onChange={(e) =>
                        changeInput({
                          ...userRegister,
                          name: { ...userRegister.name, value: e.target.value },
                        })
                      }
                    />
                  </div>

                  <Input
                    name={"Soyad"}
                    placeholder={"Soyad bilginizi girin"}
                    labelName={"Soyad"}
                    required={true}
                    regexType={"text"}
                    error={userRegister.surname.error}
                    value={userRegister.surname.value}
                    maxLength={15}
                    onChange={(e) =>
                      changeInput({
                        ...userRegister,
                        surname: {
                          ...userRegister.surname,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>

                <div className="flex-1 pl-3">
                  <Input
                    name={"Kurum Adı"}
                    placeholder={"Kurum adını girin"}
                    labelName={"Kurum Adı"}
                    required={true}
                    error={userRegister.corporation.error}
                    value={userRegister.corporation.value}
                    maxLength={30}
                    onChange={(e) =>
                      changeInput({
                        ...userRegister,
                        corporation: {
                          ...userRegister.corporation,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <div className="flex-1 pr-3">
                  <Input
                    name={"e-Posta"}
                    placeholder={"E-posta adresiniz giriniz"}
                    labelName={"e-Posta"}
                    type={"email"}
                    autocomplete="e-mail"
                    required={true}
                    error={userRegister.email.error}
                    value={userRegister.email.value}
                    maxLength={30}
                    onChange={(e) =>
                      changeInput({
                        ...userRegister,
                        email: {
                          ...userRegister.email,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="flex-1 pl-3">
                  <Input
                    regexType={"number"}
                    name={"Telefon Numarası"}
                    placeholder={"555 334 9028"}
                    labelName={"Telefon Numarası"}
                    required={true}
                    error={userRegister.cellPhone.error}
                    value={userRegister.cellPhone.value}
                    maxLength={10}
                    onChange={(e) =>
                      changeInput({
                        ...userRegister,
                        cellPhone: {
                          ...userRegister.cellPhone,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <div className="flex-1 pr-3">
                  <Input
                    name={"Şifre"}
                    placeholder={"Şifrenizi girin"}
                    labelName={"Şifre"}
                    type={"password"}
                    required={true}
                    maxLength={15}
                    error={userRegister.password.error}
                    value={userRegister.password.value}
                    tooltipPosition={"left"}
                    tooltipTitle={
                      <div className="text-neutral-400 text-sm font-medium font-['outfit'] p-2">
                        <div>Şifre en az 8 karakter olmalıdır.</div>
                        <div>Büyük ve küçük harf kullanılmalıdır.</div>
                        <div>Rakam kullanılmalıdır.</div>
                      </div>
                    }
                    onChange={(e) =>
                      changeInput({
                        ...userRegister,
                        password: {
                          ...userRegister.password,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>

                <div className="flex-1 pl-3">
                  <Input
                    name={"Şifre Tekrar"}
                    placeholder={"Şifrenizi tekrardan girin"}
                    labelName={"Şifre Tekrar"}
                    type={"password"}
                    autocomplete="current-password"
                    required={true}
                    maxLength={15}
                    error={userRegister.passwordRequret.error}
                    value={userRegister.passwordRequret.value}
                    tooltipPosition={"right"}
                    tooltipTitle={
                      <div className="text-neutral-400 text-sm font-medium font-['outfit'] p-2">
                        <div>Şifre en az 8 karakter olmalıdır.</div>
                        <div>Büyük ve küçük harf kullanılmalıdır.</div>
                        <div>Rakam kullanılmalıdır.</div>
                      </div>
                    }
                    onChange={(e) =>
                      changeInput({
                        ...userRegister,
                        passwordRequret: {
                          ...userRegister.passwordRequret,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="mt-[22px] flex">
                <Chechbox
                  onChange={(e) =>
                    changeInput({
                      ...userRegister,
                      kvkk: {
                        ...userRegister.kvkk,
                        value: e.target.checked,
                      },
                    })
                  }
                  error={userRegister.kvkk.error}
                  value={userRegister.kvkk.value}
                  text={
                    <span className="flex">
                      <a
                        href="https://kurumsalgirisimpusulasi.com/aydinlatma-metni/"
                        target="_blank"
                        className="underline"
                      >
                        Üyelik ve Bilgi Güvenliği
                      </a>
                      &nbsp;şartlarını kabul ediyorum.
                    </span>
                  }
                />
                <Button
                  text={"Kayıt Ol"}
                  className={""}
                  iconRight={RightIcon}
                  onClick={() => registerUser()}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingSpinner>
    </>
  );
}
