import React from "react";
import Header from "../../components/Header/Header";
import Business from "../../images/business.svg";
import { useAccountInformationQuery } from "../../services/AccountInformation";

export default function Profile() {
  const { isSuccess, error, data, isLoading } = useAccountInformationQuery();
  return (
    <div className="bg-[#F6F7FA] w-screen h-screen p-10 flex items-center flex-col">
      <Header />
      <div className="w-[1640px] h-[648px] bg-white rounded-[10px] border border-neutral-200 mt-4 px-[126px] py-[56px]">
        <div className="flex">
          <div className="w-[216px] h-[216px] bg-[#439DC6] rounded-[20px] flex p-4">
            <img src={Business} />
          </div>
          <div className="ml-[50px]">
            <div>
              <div className="text-zinc-800 text-3xl font-bold font-['Outfit']">
                Fatih Arıcılık
              </div>
              <div className="text-zinc-800 text-xl font-light font-['Outfit']">
                Programmer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
